<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        >Como usar o "coringa" nas pesquisas
        <span class="mx-5">(<i>clique aqui para ver o conteúdo</i>)</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>
          Nos campos de pesquisas com um símbolo de percentual "%", será
          possível usar o caractere "coringa" para criar pesquisas avançadas.
        </p>

        <p>
          <b>%</b> (percentual): é o equivalente a uma quantidade indeterminada
          de caracteres <br />
          Ex: O termo "%iPhone%" encontrará qualquer resultado que tenha a
          palavra "iPhone", ou seja, qualquer coisa antes e qualquer coisa
          depois de "iPhone" ("celular <b>iPhone</b> branco") <br />
          Ex: O termo "iPhone%" encontrará qualquer resultado que comece por
          "iPhone" (<b>iphone</b> X). <br />
          Ex: O termo "%iPhone%branco%" encontrará qualquer resultado que tenha
          "iphone" e em seguida "branco" na frase ("<b>iphone</b> X,
          caracterísicas teste <b>branco</b> mais texto no final").
        </p>

        <p>
          <b
            ><i
              >O mais indicado é sar sempre o curinga % antes, depois e no lugar
              de espaços do termo de pesquisa.</i
            ></b
          >
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {},
  name: 'joker',
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {},
  watch: {}
}
</script>
