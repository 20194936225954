<template>
  <v-container>
    <v-card class="pa-5">
      <div v-if="searching">
        <p class="text-center mt-5">
          <v-icon>fas fa-atom fa-spin</v-icon> Carregando ...
        </p>
      </div>
      <v-row class="pa-2" v-else>
        <v-col cols="12">
          <joker />
        </v-col>
        <v-col cols="6">
          <v-text-field
            @keyup.enter="fetchSearch()"
            :disabled="searching"
            label="URL Customizada"
            v-model="customUrl"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            @keyup.enter="fetchSearch()"
            :disabled="searching || (customUrl != null && customUrl != '')"
            label="Nome do produto %"
            v-model="search"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            @keyup.enter="fetchSearch()"
            :disabled="searching"
            label="Quantidade de resutlados (max 500)"
            max="500"
            type="number"
            v-model="limit"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="store"
            :items="stores"
            item-value="id"
            item-text="name"
            label="Loja"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-btn :disabled="searching" color="success" @click="fetchSearch()"
            >Pesquisar</v-btn
          >
        </v-col>
        <v-col cols="12">
          <template
            v-if="searchResult && searchResult.data && searchResult.data"
          >
            <v-card>
              <v-card-title>
                Produtos
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchInTable"
                  append-icon="fas fa-search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <table-of-products
                :products="searchResult.data"
                :search="searchInTable"
              />
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import TableOfProductsVue from '../components/TableOfProducts'
import JokerVue from './../components/Joker'
export default {
  name: 'search',
  components: {
    'table-of-products': TableOfProductsVue,
    joker: JokerVue
  },
  data() {
    return {
      store: null,
      stores: [],
      search: null,
      searchInTable: null,
      customUrl: null,
      limit: 10,
      searchResult: null,
      searching: false,
      headers: [
        { text: 'ID', value: 'asin' },
        { text: 'Nome', value: 'title' },
        { text: 'Preço', value: 'ProductPrice.price' },
        { text: '%', value: 'ProductPrice.perc_desc' },
        { text: 'Desconto', value: 'ProductPrice.discounted' },
        { text: 'Preço anterior', value: 'ProductPrice.before_discount' },
        { text: 'url', value: 'url' }
      ]
    }
  },
  mounted() {
    this.fetchStores()
  },
  created() {},
  methods: {
    fetchSearch() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `search`, {
          q: vm.search,
          limit: vm.limit,
          customUrl: vm.customUrl,
          store_id: vm.store
        })
        .then(result => {
          console.debug(result)
          vm.searchResult = result
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    fetchStores() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `stores/list`)
        .then(result => {
          if (result && result.data) {
            vm.stores = result.data
          }
          vm.searching = false
        })
        .catch(error => {
          console.debug(error)
          vm.searching = false
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    openItem(_i) {
      console.debug(_i)
      window.open(_i.url, '_blank')
    }
  }
}
</script>
